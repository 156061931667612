import { findPhoneNumbersInText, parsePhoneNumber } from "libphonenumber-js";
import { isEmpty } from "lodash";
import { format } from "date-fns";

export const getNumberInfo = (text = "", countryCode) => {
  let rs = findPhoneNumbersInText(text.trim(), countryCode);
  if (isEmpty(rs)) {
    return [];
  }
  return [rs[0].number.nationalNumber, rs[0].number.number];
};

export const blobUrl = (blob) => {
  if (!blob.url) {
    blob.url = URL.createObjectURL(blob);
  }
  return blob.url;
};

/**
 * Returns querystrings from object
 */

export const queryObjSerialize = (obj) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p) && obj[p]) {
      if (Array.isArray(obj[p])) {
        obj[p].forEach((item) => {
          str.push(
            encodeURIComponent(`${p}[]`) + "=" + encodeURIComponent(item)
          );
        });
      } else {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
  return str.join("&");
};

export const formatEmptyData = (data) => {
  let dataQuery = {};
  Object.keys(data).forEach((item) => {
    if (
      (!!data[item] && typeof data[item] !== "object") ||
      (typeof data[item] === "object" && data[item]?.length > 0)
    ) {
      dataQuery[item] = data[item];
    }
  });
  return dataQuery;
};

export const sanitizeEmptyArray = (obj) => {
  let newObj = {
    ...obj,
  };
  for (var p in newObj)
    if (newObj.hasOwnProperty(p) && Array.isArray(newObj[p])) {
      if (!newObj[p].length) delete newObj[p];
    }

  return newObj;
};

/**
 * Returns a Formatted number. ex: formatCurrencyToNumber("4,345,354.30") --> 4345354.3
 */
export function formatCurrencyToNumber(value) {
  if (!value) return 0;
  if (typeof value === "number") return value;
  return Number(value?.replace(/[^0-9.-]+/g, ""));
}

/**
 * Returns an array of key values. Where key is the parameter name.
 */

export const getUrlQueryString = () => {
  const params = location.search.replace("?", "");
  const querystring = decodeURIComponent(params.replace(/\+/g, " "));
  const items = querystring.split("&");

  return items.reduce((data, item) => {
    const [key, value] = item.split("=");
    let loweredKey = key.toLowerCase();
    // just in case a key has multiple values
    if (data[key] !== undefined) {
      if (!Array.isArray(data[loweredKey])) {
        data[loweredKey] = [data[loweredKey]];
      }
      data[loweredKey].push(value);
    } else {
      if (loweredKey !== undefined) data[loweredKey] = value;
    }
    return data;
  }, {});
};

export function formatLongDate(date, options) {
  const { isMonthShort, isDay, isShowYear = true } = options;
  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  var monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  var monthNames = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];
  var days = [
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ];

  var formatedDate = new Date(date);

  var dd = formatedDate.getDate();
  var mm = isMonthShort
    ? monthNamesShort[formatedDate.getMonth()]
    : monthNames[formatedDate.getMonth()];
  var yyyy = formatedDate.getFullYear();
  var day = days[formatedDate.getDay()];
  if (isDay) {
    return day + ", " + mm + " " + dd + ", " + yyyy;
  }

  if (!isShowYear) {
    return mm + " " + dd;
  }
  return mm + " " + dd + ", " + yyyy;
}

export function getFullTime(date) {
  const newDate = new Date(date);
  const curDate = new Date();
  let hour = newDate.getHours(),
    minutes = newDate.getMinutes();
  hour = ("0" + hour).slice(-2);
  minutes = ("0" + minutes).slice(-2);

  let formatDate = formatLongDate(date, {
    isMonthShort: true,
  });

  if (
    newDate.getFullYear() === curDate.getFullYear() &&
    newDate.getMonth() === curDate.getMonth() &&
    newDate.getDate() === curDate.getDate()
  ) {
    formatDate = "Today";
  } else if (
    newDate.getFullYear() === curDate.getFullYear() &&
    newDate.getMonth() === curDate.getMonth() &&
    newDate.getDate() === curDate.getDate() - 1
  ) {
    formatDate = "Yesterday";
  }
  return `${formatDate}, ${hour}:${minutes}`;
}

export function diffDay(date1, date2) {
  const diffTime = new Date(date2).getTime() - new Date(date1).getTime();

  return Math.ceil(diffTime / (1000 * 3600 * 24));
}

/**
 * Returns a Formatted Date Time. Default returns Date with Time and TimeZone.
 */
export function formatDate(utcString, dateOnly = true) {
  if (!utcString) return "";
  var time = new Date(utcString);
  if (dateOnly) {
    return format(time, "dd/MM/yyyy");
  }
  return format(time, "dd/MM/yyyy h:mm a z").replace("'", ""); //replacing the ' here cause IE11 is weird.
}

/**
 * Generate custom Breadcrumbs array with label and href
 */

// TODO: update href with filter query whenever both design and api are updated
export function generateBreadcrumbs(keys, data, href) {
  let rs = [];
  keys.forEach((item) => {
    if (typeof data[item.key] === "string") {
      rs.push({
        label: data[item.key],
        href: "/my-company",
      });
    } else {
      let queryStrings = queryObjSerialize(item.params);
      rs.push({
        label: data[item.key]?.name,
        href: `/${href}?${queryStrings}`,
      });
    }
  });
  return rs;
}

/**
 * Group items base on first letter
 */

export function groupItems(arr = [], key, resultKey = "items") {
  const map = arr.reduce((acc, val) => {
    let char = val[key].charAt(0).toUpperCase();
    acc[char] = [].concat(acc[char] || [], val);
    return acc;
  }, {});

  const result = Object.keys(map).map((el) => ({
    letter: el,
    [resultKey]: map[el],
  }));
  return result;
}

export const formatPhoneByDesign = (phoneNumber) => {
  const formatPhone = parsePhoneNumber(
    phoneNumber.split(" ").join("")
  ).formatInternational();
  const firstSpace = formatPhone.indexOf(" ");
  return `(${formatPhone.slice(0, firstSpace)})${formatPhone.slice(
    firstSpace
  )}`;
};

/**
 * Clean string/number array by remove duplicated item
 */

export const uniqArray = (arr) => [...new Set([...arr])];

/**
 * Convert Object to Array without saving key
 */
export const objToArray = (obj) => {
  if (!obj) return [];
  return Object.keys(obj).map((key) => obj[key]);
};

export const getCurrentDayTime = () => {
  let today = new Date();
  const date = `${today.getFullYear()}-${
    today.getMonth() + 1
  }-${today.getDate()}`;
  const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
  return `${date} ${time}`;
};

export const getDefaultImageUrl = (object) => {
  return _.get(object, "url", "");
};

export const getThumbImageUrl = (object) => {
  return _.get(object, "thumbUrl", "");
};

export const getSubProductTypeDefaultImages = (
  produce,
  productTypesParams = ""
) => {
  const productTypes = productTypesParams;

  const produceProductTypeId =
    produce?.productType?.id || produce?.productType?._id;

  const productType = productTypes?.find(
    (item) => item.id === produceProductTypeId
  );

  const images = productType?.images;

  return !!images?.length ? images : [{ url: "" }];
};

export const formatPrice = (price, currencySymbol) => {
  const absPrice = Math.abs(price);
  const formattedPrice = absPrice?.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (!price) {
    return 0;
  }

  if (!currencySymbol) {
    return formattedPrice;
  }

  if (currencySymbol && formattedPrice)
    return `${currencySymbol}${formattedPrice}`;
  else return "";
};

export const parseNumber = (value) => {
  if (typeof value === "number") {
    return value;
  } else {
    return +value.split(",").join("");
  }
};

export const formatTime12 = (dateString) => {
  const date = new Date(dateString);
  let time = "";
  let hour = date.getHours();
  let min = date.getMinutes();
  let ampm = hour >= 12 ? "pm" : "am";

  if (hour === 24) {
    ampm = "am";
  }

  hour = hour % 12;
  hour = hour ? hour : 12;
  min = min < 10 ? "0" + min : min;

  time = `${hour}:${min} ${ampm}`;

  return time;
};

export const formatTime24 = (dateString) => {
  const date = new Date(dateString);
  let time = "";
  let hour = date.getHours();
  let min = date.getMinutes();

  min = min < 10 ? "0" + min : min;

  time = `${hour}:${min}`;

  return time;
};

export const ddmmmyyyyFormat = (date, includesTime = false, hour12 = true) => {
  const newDate = formatLongDate(date, {
    isMonthShort: true,
  });
  const arrays = newDate.split(",");
  let result = "";

  if (arrays.includes(" NaN")) {
    return "";
  }

  result = arrays[0].split(" ").reverse().join(" ") + arrays[1];

  if (includesTime && hour12) {
    return `${result} - ${formatTime12(date)}`;
  }

  if (includesTime && !hour12) {
    return `${result} - ${formatTime24(date)}`;
  }

  return result;
};

export const yyyymmddFormat = (dateString) => {
  const day = new Date(dateString);

  const dd = `0${String(day.getDate())}`.slice(-2);
  const mm = `0${String(day.getMonth() + 1)}`.slice(-2);
  const yyyy = day.getFullYear();

  if (!dateString) {
    return "";
  }

  return yyyy + "-" + mm + "-" + dd;
};

export const roundNumber = (number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100;
};

export const blobUrlToFile = async (blobUrl, fileName) => {
  const file = await fetch(blobUrl)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new File([blob], fileName, {
          type: blob.type,
        })
    );

  return file;
};

export const normalizeObjectData = (obj) => {
  let result = { ...obj };

  Object.keys(result).forEach((key) => {
    if (!result[key] && typeof result[key] !== "boolean") {
      delete result[key];
    }
  });

  return result;
};

export const getUTCFormat = (dateParam) => {
  const date = new Date(dateParam);
  const dateString = [
    date.getUTCFullYear(),
    ("0" + (date.getUTCMonth() + 1)).slice(-2),
    ("0" + date.getUTCDate()).slice(-2),
  ].join("-");

  const timeString = [
    ("0" + date.getUTCHours()).slice(-2),
    ("0" + date.getUTCMinutes()).slice(-2),
    ("0" + date.getUTCSeconds()).slice(-2),
  ].join(":");

  return `${dateString} ${timeString}`;
};

export const secondsToTimeString = (passedSeconds) => {
  const dateObj = new Date(passedSeconds * 1000);
  const hours = dateObj.getUTCHours();
  const minutes = dateObj.getUTCMinutes();
  const seconds = dateObj.getSeconds();
  const timeString =
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0") +
    ":" +
    seconds.toString().padStart(2, "0");

  return timeString;
};

export const specialQueryObject = (path) => {
  const [, queryString = ""] = path?.split("?");

  return queryString.split("&").reduce((prev, curr) => {
    const [key, value] = curr.split("=");
    prev.set(key, value);
    return prev;
  }, new Map());
};

export function removeEmpty(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === "" ||
      (Array.isArray(obj[propName]) && obj[propName].length === 0)
    ) {
      delete obj[propName];
    } else if (typeof obj[propName] === "object") {
      removeEmpty(obj[propName]);
    }
  }
  return obj;
}

export function convertNullToString(nullItem) {
  if (nullItem === null) {
    return "";
  }
}

export function postTextShow(string) {
  return string?.split("\n").map((str) => <p style={{margin:"0px 0px 7px"}}>{str}</p>);
}
